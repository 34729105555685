import steps_wizard from 'bulma-extensions/bulma-steps/src/js/index';
import notification from 'ios-overlay';
import { Spinner } from 'spin.js';
import isThisSecond from 'date-fns/is_this_second/index.js';
import AnyPicker from 'anypicker';
import _ from 'lodash';

(function ($) {
    let $time_fields = $('[type=time]');

    if ($time_fields.length) {
        $time_fields.AnyPicker({
            mode: 'datetime',
            rowsNavigation: "scroller+buttons",
            inputDateTimeFormat: 'HH:mm',
            dateTimeFormat: 'hh:mm AA',
            theme: 'Android'
        });

        $time_fields.on('focus', function () {
            $('#ap-button-set').on('click', function () {
                $time_fields.trigger('change');
            })
        });

    }

    let $form_validate;
    let $form_question = $('.form-question');
    let last_error = '';

    if (typeof window.parent['can_proceed'] === 'undefined') {
        window.parent.can_proceed = false;
    }


    /* Default enable or disable textbox based on radio's value */
    $(window).on('load', function () {
        var $text_elements = $("input[type=radio]").parent().parent().find("input[type=text]");

        if (!$text_elements) {
            return;
        }

        $text_elements.each(function (index, curr_text_element) {
            let $curr_text_element = $(curr_text_element);
            let $checked_radio_element = $curr_text_element.parent().parent().find("input[type=radio]:checked");

            if ($checked_radio_element.val() == 1) {
                $curr_text_element.prop('disabled', false);
            } else {
                $curr_text_element.prop('disabled', true);
            }

        });
    });

    let is_valid = false;
    let validate_on_demand = _.debounce(function(el, event) {

        let $el = $(el);
        enable_next_block($el);
        let { method, callback, callback_params: params, trigger } = $el.data();

        if (method in $.validator.methods) {
            $el.rules('add', method);
        }

        $form_validate.element($el);

        if (callback in callbacks) {
            let obj = {};

            $.map(params.replace(/\s/g, '').split(','), function (row) {
                let pair = row.split(':');
                return obj[pair[0]] = pair[1];
            });

            callbacks[callback].call(obj, $el);
        }

        is_valid = $el.valid();

        if ($el.hasClass('notify') && method in $.validator.methods) {
            $el.rules('remove', method);
        }

        if (trigger && is_valid === false && $el.val()) {

            let notify = notification({
                duration: 2e3,
                text: last_error,
                icon: '/skin/images/cross.png',
                onhide: function () {
                    let spinner = new Spinner({
                        color: '#fff',
                        className: 'spinner',
                        scale: 2.5,
                    });

                    notification({
                        spinner: spinner.spin($('<div />')),
                        text: 'Loading...',
                        duration: 1e3,
                        onhide: function () {
                            if (typeof triggers[trigger] === 'function') {
                                triggers[trigger](event);
                            }
                        }
                    });
                }
            });

        }
    }, 500);

    let $progress = $('.progress').hide();

    $(document).on('change', '.file-input', function (e) {
        let $el = $(e.target);
        $.map($el.prop('files'), function (file) {
            let $file = $('<span />').addClass('file-name').text(file.name);
            $('label.file-label').append($file);
        });
    });

    $(window).on('load', function () {

        // question specific paperwork
        let $paperwork = $(document).find('.paperwork');
        if ($paperwork.length > 0) {

            let paperwork_data = $paperwork.data();

            if (paperwork_data && Object.keys(paperwork_data).length) {
                let $container = $('label.file-label');
                $.post('/m/form-paperwork', paperwork_data, function (paperwork) {
                    $.map(paperwork, function (item) {
                        let $paperwork_item = $('<span />').text(item.file_name).addClass('file-name');
                        $container.append($paperwork_item);
                    });
                });
            }
        }

        // check if we have 'autofill' fields and trigger form-save once
        let autofill_fields = $('[data-with=autofill]');
        if (autofill_fields.length) {
            autofill_fields.each(function(index, el) {

                let fieldInputTypes = []
                $(el).parents('.form-question').find('input').not('input[type=hidden]').each((index, el) => {
                    fieldInputTypes.push($(el).prop('type'))
                    fieldInputTypes = [... new Set(fieldInputTypes)]
                })

                if ($(el).prop('type') === 'text' || fieldInputTypes.length === 1) {
                    submit_answer(el);
                }
            });
        }

        enable_next_block();
    });

    let enable_next_block = _.debounce(function ($el) {
        let $current_parent;

        if ($el) {
            $current_parent = $el.parents('.form-question');
            let $flag = $current_parent.find('.answer-flag');
            if (!$flag.length) {
                $('<div />').addClass('answer-flag').appendTo($current_parent);
                $current_parent.removeClass('has-error');
            }
        }

        $form_question.each(function (index, current) {
            let $current = $(current);
            if (!$current.find('.answer-flag').length && index > 0) {
                $current.addClass('disable-events');
            }
        });

        let $disabled_elements = $('.disable-events');
        if ($disabled_elements.first().prev().find('.answer-flag').length) {
            $disabled_elements.first().removeClass('disable-events');
        }

        last_error = false;

        if (typeof $form_validate !== 'undefined') {
            $form_validate.form();
        }
    }, 500);

    // callbacks
    let callbacks = {
        toggle_disable_field: function ($el) {
            let el_method = $el.data('method');
            let $target_field = $el.parents('.form-question').find('.field')
                .eq(this.eq).find('input');

            let bool = this.disabled === 'true';
            if ($el.prop('type') === 'checkbox') {
                bool = $el.is(':checked') === bool;

                if (bool === true) {
                    $target_field.removeAttr('required');
                    $el.attr('required', true).rules('add', el_method);
                } else {
                    $target_field.attr('required', true);
                    $el.removeAttr('required').rules('remove', el_method);
                }

            }

            if ($target_field.prop('type') === 'text') {
                if (bool === true) {
                    $target_field.prop('required', false);
                } else {
                    $target_field.prop('required', true);
                }
            }

            if ($el.val()) {
                $target_field.prop('disabled', bool);
            } else {
                $target_field.prop('disabled', false);
            }

            if (($el.prop('type') === 'checkbox' || $el.prop('type') === 'radio') && $el.is(':checked')) {
                if (bool) {
                    $target_field.val('');
                    submit_answer($target_field, true);
                }
            }

            $form_validate.element($el);
            $form_validate.element($target_field);
        },
        copy_value_to_field: function ($el) {
            let $checkboxes = $el.parents('.form-question').find('input[type=checkbox]');
            let $text_field = $el.parents('.form-question').find('.field').eq(this.eq).find('input');
            let $value = [];

            $.map($checkboxes, function (checkbox) {
                let $checkbox = $(checkbox);
                if ($checkbox.is(':checked')) {
                    $value.push($checkbox.next().text());
                }
            });

            $text_field.val($value.join(', '));
            submit_answer($text_field);
        }
    };

    // modal
    let modal = {}

    $(window).on('load', function () {
        modal = {
            $modal: $(document).find('#modal-form'),
            open: function () {
                console.log(this.$modal);
                this.$modal.addClass('is-active');
                $('html', document).addClass('is-clipped');

                $('.delete', this.$modal).on('click', function () {
                    modal.close();
                });

                return this;
            },
            close: function () {
                this.$modal.removeClass('is-active');
                $('html', document).removeClass('is-clipped');

                this.content(null);
            },
            content: function (content) {
                let modal_card_body = this.$modal
                    .find('.modal-card-body').empty().height(480);
                modal_card_body.append(content);

                if (content) {
                    content.height(modal_card_body.height());
                }

                return this;
            }
        }
    })

    if (typeof window.parent['modal_api'] === 'undefined') {
        window.parent.modal_api = modal;
    }

    // triggers
    let triggers = {
        form_corrective: function (event) {
            let { target: el } = event;

            let $el = $(el);
            let input_type = $el.prop('type');
            let { id_question, id_option, id_entity, entity_type } = $el.data();

            if (!$el.is(':checked') && $.inArray(input_type, ['radio', 'checkbox']) !== -1) return;

            let content = $('<iframe />').attr({
                src: `/m/form/id/${id_entity}/form_type/3/via/dialog/question/${id_question}/entity_type/${entity_type}`,
                width: '100%',
            });

            modal.content(content).open();
        }
    };

    $(document).on('click', '#btn-corrective-action', function () {
        $('#modal-error').removeClass('is-active');
        modal.open();
    });

    // iframe api
    window.iframe_api = {
        close: function () {
            window.parent.modal_api.close();
        }
    };

    $(document).on('click', '#iframe_close', function (e) {
        e.preventDefault();

        if (!window.parent.can_proceed) {
            alert('Please fill up the corrective actions/comments first');
            return false;
        }

        iframe_api.close();
    });

    let $product_type = $('#product_type').val();
    let $temp_range_message = $.validator.format('Temperature is out of <strong>{0}</strong> range, report to Supervisor ' +
        '<strong>immediately</strong> and note in Corrective Action Form');


    $.validator.addMethod('temperature_range', function (val, el) {
        let {min, max} = $.parseJSON($('#temp_range').val());
        min = parseFloat(min);
        max = parseFloat(max);

        if (min >= 0) {
            return (min <= val) && (val <= max);
        } else {
            return (min >= val) && (val >= max);
        }
    }, 'Temp Out of Range <br> See Supervisor');

    $.validator.addClassRules('notify-temperature-range', {
        number: true,
        required: true,
        temperature_range: true,
    });

    $.validator.addMethod('temperature_unavailable', function (val, el) {
        return false;
    }, "Note in Corrective Action");

    $.validator.addMethod('supervisor', function (val, el) {
        return false;
    }, 'Report to Supervisor <strong>immediately</strong>.');

    $.validator.addMethod('note_in_corrective', function (val, el) {
        return false;
    }, 'Note in Corrective Action');

    $.validator.addMethod('require_correct_seal', function (val, el) {
        return false;
    }, 'Enter correct Seal # <br> Note in Corrective Action');

    $.validator.addMethod('warn_safety_dock', function (val, el) {
        return false;
    }, 'Wait until container/truck has been backed onto Dock, then tick <strong>Yes</strong> to proceed');

    $.validator.addMethod('warn_safety_mover', function (val, el) {
        return false;
    }, 'Wait until the prime mover engine has been switched off and vehicle keys secured, then tick <strong>Yes</strong> to proceed');

    $.validator.addMethod('warn_safety_trailer', function (val, el) {
        return false
    }, 'Lock trailer using pallets or valve locks in front of trailer pin, then tick <strong>Yes</strong> to proceed');

    $.validator.addMethod('warn_safety_remove_locks', function (val, el) {
        return false;
    }, 'Remove pallets or valve locks from under the trailer pin');

    $.validator.addMethod('warn_label_checks', function (val, el) {
        return false;
    }, 'Check labels properly and make sure correct labels are used');

    $.validator.addMethod('warn_upload_documents', function (val, el) {
        return false;
    }, 'Please upload documents.');

    $.validator.addMethod('request_document', function (val, el) {
        return false;
    }, 'Request document from customer.');

    $.validator.addMethod('corrective_actions', function (val, el) {
        return false;
    }, 'See Supervisor <br> Note in Corrective Actions');

    $.validator.addMethod('damage_report', function (val, el) {
        return false;
    }, 'See Supervisor <br> Complete Damage Report');

    let xhr_answer;
    function submit_answer(e) {
        let $el = $(e.target || e);
        let data = $el.data();
        let value = $el.val();

        if ($el.prop('type') === 'checkbox') {
            value = $el.is(':checked') ? $el.data('checkbox_value') : 0;
            $el.data('value', value);
        }

        // if (typeof xhr_answer !== 'undefined') {
        //     xhr_answer.abort();
        // }

        function lead_zero(i) {
            return (i < 10) ? `0${i}` : i;
        }

        function getTime()
        {
            let d = new Date(Date.now());
            return `${lead_zero(d.getHours())}:${lead_zero(d.getMinutes())}`;
        }

        let $field_time_unpack_start = $('[name=time_unpack_start]');
        if (data.sequence === 1 && !$field_time_unpack_start.val()) {
            $field_time_unpack_start.val(getTime());
            $field_time_unpack_start.trigger('change');
        }

        let canSubmitTheForm = false;
        if ($el.prop('type').includes('radio') && $el.prop('checked')) {
            canSubmitTheForm = true
        }

        if (!$el.prop('type').includes('radio')) {
            canSubmitTheForm = true;
        }

        if (canSubmitTheForm) {
            xhr_answer = $.post('/m/form-save-answer', {...data, value});
            enable_next_block($el);
        }
    }

    // watch changes and save to database
    let debounce_submit = _.debounce(submit_answer, 500);

    $(document).on('change propertychange input',
        '.whs-form-questions input, .whs-form-questions textarea',
        debounce_submit
    );

    function ajax_form_submit(form) {
        $(form).ajaxSubmit({
            beforeSend: function () {
                $progress.removeClass('is-danger').addClass('is-success').fadeIn();
                $progress.val(0);

                if ($('.file-input').prop('files').length === 0) {
                    $progress.hide();
                }

                $('#iframe_close').prop('disabled', true);
                $('#btn_next').prop('disabled', true).text('Saving...');
            },
            uploadProgress: function (ev, pos, total, curr) {
                $progress.val(curr).text(curr + '%');
            },
            success: function () {
                $progress.val(0).hide();
                // $('.file-name').remove();

                window.parent.can_proceed = true;
                $('#iframe_close').prop('disabled', false);
                $('#btn_next').delay(2e3).text('Changes Saved!').delay(2e3).prop('disabled', false).text('Save Comments');
            },
            error: function () {
                $progress.removeClass('is-success').addClass('is-danger');
                $(document).find('.file-input').val('');
                $('.file-name').remove();
            }
        });
    }

    let form_el = '.whs-form-questions';
    let $btn_next = $('#btn_next');
    let $step_content_length = $('.step-content').length - 1;
    let number_of_invalids = 0;

    let form_inward_el = '.whs-form-inward';
    let $form_inward = $(form_inward_el).validate({
        errorPlacement: function () {},
        highlight: function (error) {
            let $current = $(error).parents('tr');
            $current.addClass('has-error');

            if ($('#flag-is-new').length === 0) {
                window.scrollTo(0, $('.has-error').first().offset().top);
            }
        },
        unhighlight: function (error) {
            $(error).parents('tr').removeClass('has-error');
        },
        submitHandler: function (form) {
            form.ajaxSubmit({
                success: function() {
                    window.location.reload()
                }
            });
        }
    });

    /* Input Restrictions that trigger on user input (Reinier) */

    /* 1. Alphanumeric Restriction */
    $('input[data-input_type=alphanumeric]').on('input', function () {
        if (/[^a-zA-Z\d+/\-]/.test(this.value)) {
            this.value = (this.getAttribute('data-previous_value'));
        } else {
            this.value = this.value.toUpperCase();
            this.setAttribute('data-previous_value', this.value);
        }
    });

    /* 2. Numbers Restriction */
    $('input[data-input_type=numbers]').on('input', function () {
        let value = (this.value).match(/-?\d+(\.\d+)/g);
        if (value) { this.value = value.map(Number).pop(); }
    });

    /* 3. Positive Integers Restriction */
    $('input[data-input_type=positive_integers]').on('input', function () {
        if (/[^\d]/.test(this.value)) {
            this.value = (this.getAttribute('data-previous_value'));
        } else {
            this.value = Math.abs(this.value);
            this.setAttribute('data-previous_value', this.value);
        }
    });

    $(window).on('load', function () {
        if (typeof $form_inward !== 'undefined') {
            $form_inward.form();
        }

        if ($(form_el).length) {
            new steps_wizard(form_el, {
                beforeNext: function (step_id) {
                    $form_validate.form();

                    if (typeof $form_inward !== 'undefined') {
                        $form_inward.form();
                    }

                    setTimeout(function () {
                        let $header_title = $('.card-header-title');
                        if ($('.has-error, .error').length === 0) {
                            let id_entity = $header_title.data('id_entity');
                            let entity_type = $('[name=entity_type]').val();

                            if (!id_entity) { id_entity = $('[name=entity_id]').val(); }

                            $.ajax({
                                method: 'post',
                                url: '/m/form-done',
                                data: {
                                    id: id_entity,
                                    type: entity_type,
                                },
                                beforeSend: function () {
                                    $btn_next.prop('disabled', true);
                                    $btn_next.text('Please wait...');
                                },
                                success: function (data) {
                                    window.location = data.url;
                                }
                            });

                            return true;
                        }
                    }, 1e3);

                    if ($form_inward) {
                        if ($('.has-error, .error').length > 0 || $form_inward.numberOfInvalids() > 0) {
                            alert('Check whether all questions have been answered.');
                            return ['error'];
                        }
                    }

                    if ($('input[aria-invalid=true]').length > 0) {
                        alert('Check whether all questions have been answered');
                        return ['error'];
                    }

                    return [];
                },
                onShow: function () {
                    $form_validate = $(form_el).validate({
                        ignore: '.disabled',
                        errorPlacement: function (error) {
                            last_error = error.prop('innerHTML');
                        },
                        onkeyup: _.debounce(validate_on_demand, 2e3),
                        onclick: validate_on_demand,
                        submitHandler: ajax_form_submit,
                        showErrors: function (map, list) {
                            this.defaultShowErrors();
                        },
                        highlight: function (el) {
                            setTimeout(function () {
                                let $current = $(el).parents('.form-question');
                                if (!$current.find('.answer-flag').length && !$current.hasClass('disable-events')) {
                                    $current.addClass('has-error');

                                    if ($('#flag-is-new').length === 0 && is_valid) {
                                        window.scrollTo(0, $('.has-error').first().offset().top);
                                    }
                                }
                            }, 800);
                        },
                        unhighlight: function (el) {
                            $(el).parents('.form-question').removeClass('has-error');
                        }
                    });

                    if (typeof $form_validate !== 'undefined') {
                        $form_validate.form();
                    }

                    $('.form-question .error').each(function (index, input) {
                        let $input = $(input);
                        let $current_block = $input.parents('.form-question');
                        let $has_flag = $current_block.find('.answer-flag').length;
                        let $has_na_option = $current_block.find('.option-16');

                        if ($has_flag && $has_na_option.length && $input.attr('type') === 'text') {
                            if ($has_na_option.is(':checked')) {
                                $input.addClass('disabled').removeClass('error').prop('disabled');
                            }
                        }

                    });
                },
                onFinish: function (e) {
                }
            });
        }

        $(document).on('change propertychange input', `${form_inward_el} input, ${form_inward_el} select`, _.debounce(function (e) {
            $(form_inward_el).ajaxSubmit({
                success: function(html) {
                    let id_temp_def = '#temp-def';
                    let $temp_def = $(html).find(id_temp_def);

                    $(id_temp_def).replaceWith($temp_def);
                }
            });
        }, 500));
    });

})(jQuery);
